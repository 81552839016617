<template>
  <div>
    <div>
      <system-bar></system-bar>
      <admin-menu></admin-menu>
    </div>
    <div>
      <v-card>
        <v-card-title>
          <v-btn @click="redirectTo('/admin/users/create-doctor')"
            >Create DOCTOR</v-btn
          >
        </v-card-title>
        <v-card-text>
          <v-data-table :headers="headers" :items="doctor">
            <template v-slot:item.actions="{ item }">
              <v-btn @click="redirectTo('/admin/users/edit-doctor/' + item._id)"
                >Edit</v-btn
              >

              <!--v-btn
                @click="approve(item._id)"
                v-if="item.status == 'Waiting'"
                color="primary"
                >Approve</!--v-btn
              -->
              <!-- delete dialog -->
              <v-dialog v-model="item.deleteDialog" persistent max-width="520">
                <template v-slot:activator="{ on, attrs }">
                  <!--v-btn color="primary" dark > Open Dialog </!--v-btn-->
                  <v-btn color="error" v-bind="attrs" v-on="on"
                    >Delete Doctor {{ item.fullname }}</v-btn
                  >
                </template>
                <v-card>
                  <v-card-title class="headline">
                    Confirm to delete {{ item.fullname }}?
                  </v-card-title>
                  <!--v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</!--v-card-text
              -->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn color="green darken-1" text @click="item.deleteDialog = false">
                      Cancel
                    </v-btn>
                    <v-btn color="green darken-1" text @click="remove(item)">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>

              <!-- approve dialog -->
              <v-dialog
                v-model="item.approveDialog"
                persistent
                max-width="520"
                v-if="item.status == 'Waiting'"
              >
                <template v-slot:activator="{ on, attrs }">
                  <!--v-btn color="primary" dark > Open Dialog </!--v-btn-->
                  <v-btn color="secondary" v-bind="attrs" v-on="on"
                    >Approve Doctor {{ item.fullname }}
                  </v-btn>
                </template>
                <v-card>
                  <v-card-title class="headline">
                    Confirm to approve {{ item.fullname }}?
                  </v-card-title>
                  <!--v-card-text
                >Let Google help apps determine location. This means sending anonymous
                location data to Google, even when no apps are running.</!--v-card-text
              -->
                  <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                      color="green darken-1"
                      text
                      @click="item.approveDialog = false"
                    >
                      Cancel
                    </v-btn>
                    <v-btn color="green darken-1" text @click="approve(item)">
                      Confirm
                    </v-btn>
                  </v-card-actions>
                </v-card>
              </v-dialog>
            </template>
          </v-data-table>
        </v-card-text>
      </v-card>
    </div>
  </div>
</template>

<script>
import SystemBar from "../../../library/system-bar/system-bar";
import AdminMenu from "../../menubar/menubar";
import { mapGetters, mapActions } from "vuex";
import axios from "axios";

export default {
  name: "AdminList",
  components: {
    "admin-menu": AdminMenu,
    "system-bar": SystemBar,
  },
  data() {
    return {
      approveDialog: false,
      deleteDialog: false,
      headers: [
        {
          value: "email",
          text: "Email",
        },
        {
          value: "fullname",
          text: "Name/Surname",
        },
        { value: "feedback", text: "Feedback" },
        {
          value: "totalCases",
          text: "Cases",
        },
        {
          value: "lastPayment",
          text: "Last Payment",
        },
        {
          value: "actions",
          text: "Actions",
        },
      ],
      doctor: [],
      url: process.env.VUE_APP_LOYALTY_API_URI,
      custId: process.env.VUE_APP_OGF_CUST_ID,
      //test: false,
    };
  },
  mounted() {
    this.init();
  },
  computed: {
    ...mapGetters({
      listDoctor: "BFC/getDoctorList",
    }),
  },
  methods: {
    ...mapActions({
      setlistDoctor: "BFC/setListDoctor",
    }),
    redirectTo(path) {
      this.$router.push(path);
    },

    async remove(item) {
      //
      let payload = {
        ...item,
        custId: this.custId,
      };
      console.log(payload);

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/deleteDoctor",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //
              this.init();
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
      item.deleteDialog = false;
    },
    async approve(item) {
      let payload = {
        ...item,
        custId: this.custId,
      };
      console.log(payload);

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/approveStatus",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //
              this.init();
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }

      item.approveDialog = false;
    },

    async init() {
      let payload = {
        custId: this.custId,
      };

      try {
        await axios
          .post(
            this.url + "/api/bfc/v1/admin/listDoctorUser",
            payload, // need to change
            {
              headers: {
                // Overwrite Axios's automatically set Content-Type
                "Content-Type": "application/json",
              },
            }
          )
          .then(async (response) => {
            // console.log(response);
            console.log(response.data);
            if (response.data.statusCode == 0) {
              //console.log(response)
              await this.setlistDoctor(response.data.data);
              console.log(this.listDoctor);
              this.doctor = this.listDoctor.map((item) => {
                console.log(item);
                //let n = 1;
                return {
                  //no: n,
                  _id: item._id,
                  email: item.email,
                  fullname: item.firstname + " " + item.lastname,
                  //memberId: item.memberId._id,
                  totalCases: item.totalCases,
                  lastPayment: "-",
                  status: item.status,
                  deleteDialog: false,
                  approveDialog: false,
                };
                //n++;
              });
            } else {
              // show error
              console.log("error response");
            }
          });
      } catch (e) {
        //
        console.log(e);
      }
    },
  },
  watch: {
    approveDialog: {
      handler(val) {
        console.log(val);
      },
    },
    deleteDialog: {
      handler(val) {
        console.log(val);
      },
    },
  },
};
</script>
