var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',[_c('system-bar'),_c('admin-menu')],1),_c('div',[_c('v-card',[_c('v-card-title',[_c('v-btn',{on:{"click":function($event){return _vm.redirectTo('/admin/users/create-doctor')}}},[_vm._v("Create DOCTOR")])],1),_c('v-card-text',[_c('v-data-table',{attrs:{"headers":_vm.headers,"items":_vm.doctor},scopedSlots:_vm._u([{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{on:{"click":function($event){return _vm.redirectTo('/admin/users/edit-doctor/' + item._id)}}},[_vm._v("Edit")]),_c('v-dialog',{attrs:{"persistent":"","max-width":"520"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"error"}},'v-btn',attrs,false),on),[_vm._v("Delete Doctor "+_vm._s(item.fullname))])]}}],null,true),model:{value:(item.deleteDialog),callback:function ($$v) {_vm.$set(item, "deleteDialog", $$v)},expression:"item.deleteDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirm to delete "+_vm._s(item.fullname)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.deleteDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.remove(item)}}},[_vm._v(" Confirm ")])],1)],1)],1),(item.status == 'Waiting')?_c('v-dialog',{attrs:{"persistent":"","max-width":"520"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"color":"secondary"}},'v-btn',attrs,false),on),[_vm._v("Approve Doctor "+_vm._s(item.fullname)+" ")])]}}],null,true),model:{value:(item.approveDialog),callback:function ($$v) {_vm.$set(item, "approveDialog", $$v)},expression:"item.approveDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline"},[_vm._v(" Confirm to approve "+_vm._s(item.fullname)+"? ")]),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){item.approveDialog = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"color":"green darken-1","text":""},on:{"click":function($event){return _vm.approve(item)}}},[_vm._v(" Confirm ")])],1)],1)],1):_vm._e()]}}])})],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }